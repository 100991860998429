@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Urbanist";

  /* user-select: none; */
}
html,
body,
#root {
  width: 100vw;
  height: 100dvh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

/* *::selection {
  background-color: #F6FAFD; 
  color: #000;
} */

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  background-color: #ffffff;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #ffffff;
  border-left: 1px solid #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 6px;
  background-color: #d7d7d7;
}

input::-webkit-search-clear-button {
  display: none;
}

.navItem {
  height: 100%;
  cursor: pointer;
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding-top: 4px;
  border-bottom: 4px solid transparent;
}

.navItem.active {
  color: #138fd7;
  height: 100%;
  border-bottom: 4px solid #138fd7;
}
.__floater__arrow span svg polygon {
  /* fill: #e7f4fb; */
}

.lineclampCard {
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  white-space: break-spaces; */
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 4 !important;
  overflow: hidden !important;
}
